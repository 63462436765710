<template>
  <v-container fluid class="country-select">
    <h3 class="mb-7">国家/地区</h3>
    <v-row class="row-box">
      <v-col cols="5" class="selectb">
      <v-combobox
        v-model="groupV"
        :items="getNumber"
        :label="'所有国家/地区(' + list.length + '个)'"
        outlined
      >
      </v-combobox>
    </v-col>
    <v-col cols="6.2">
      <v-text-field
            v-model="search"
            label="搜索"
            outlined
            clearable
            prepend-inner-icon="mdi-magnify"
            hide-details="auto"
            clear-icon="mdi-close-circle-outline"
          ></v-text-field>
    </v-col>
    </v-row>
    <div class="listContent">
          <v-row class="nation-box nation-head">
            <v-col class="colput">
            <v-checkbox  :label="allCheck?'国家/地区':'国家/地区'" color="#6200ee" v-model="allCheck" @change="allCheckChange" ></v-checkbox>
          </v-col>
            <!-- 批量修改选择国家的发布数量 -->
            <v-col class="colput" v-if="publishV==-3"><div class="input-box">
              <v-text-field
                v-model="allNumber"
                class="treeview-input"
                label="批量修改发布数量"
                outlined
                hide-details="auto"
                single-line
                @input="changeAllNumber"
              ></v-text-field>
            </div>
          </v-col>
          </v-row>
         <div class="line"></div>

        <div v-for="item in currentList" :key="item.id">
          <v-row class="nation-box" v-show="search?item.name.includes(search):true">
           <v-col class="colput"><v-checkbox color="#6200ee" class="checkout-text" :label="item.name" v-model="item.ischeck" @change="(v)=>handleCheckboxChange(v,item)" ></v-checkbox></v-col> 
           <v-col class="colput"><div class="input-box">
              <v-text-field
                v-if="item.id >= 0 && publishV == -3"
                v-model="item.value"
                class="treeview-input"
                label="发布数量"
                :rules="nonNegativeIntegerRule"
                outlined
                hide-details="auto"
                single-line
                @input="updateItemValue(item)"
              ></v-text-field>
            </div>
          </v-col> 
          </v-row>
        </div>
      </div>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    groupV:'',
    allCheck:false,
    groupValue:0,
    currentList:[],
    allNumber: '', // 批量修改选择国家的发布数量
    nonNegativeIntegerRule: [
        v => v === '' || /^\d+$/.test(v) || '请输入非负整数', // 输入为空或为非负整数
      ],
    selected: '', // v-combobox 绑定的变量
    list: [], // v-combobox 的选项列表
    search: null, // 搜索框的值
    aid: "",
    listOpen:[],
    listUnopen:[],
  }),
  props: {
    publishV: {
      type: Number,
      default: -1,
    },
  },
  watch:{
    groupV(){
      this.groupChange()
    },
    publishV(newVal){
      if(newVal==-3){
        this.currentList.forEach((item)=>{
          this.updateItemValue(item)
        })
      }
    }
  },
  computed:{
    getNumber(){
      return [`所有国家/地区(${this.list.length}个)`,`已开放(${this.listOpen.length}个)`,`未开放(${this.listUnopen.length}个)`]
    }
  },
  methods: {
    allCheckChange(v){
      this.currentList.forEach((item)=>{
        item.ischeck=v
        this.handleCheckboxChange(v,item)
      })
    },
    changeAllNumber(){
      this.currentList.forEach((item)=>{
        if(item.ischeck){
          item.value=this.allNumber
        }
      })
    },
    groupChange(){
      this.groupValue=this.getNumber.indexOf(this.groupV)
      if(this.groupValue==0){
        this.currentList=[...this.listOpen,...this.listUnopen]
      }else if(this.groupValue==1){
        this.currentList=this.listOpen
      }else if(this.groupValue==2){
        this.currentList=this.listUnopen
      }
      this.allCheck=this.currentList.every((item)=>item.ischeck)
      //让中国排在第一，中国id为48
      this.currentList.sort((a,b)=>a.id==48?-1:b.id==48?1:0)

      if(this.currentList.length==0){
        this.allCheck=false
      }
},
updataGlist(){
  this.groupV=this.getNumber[0]
},
handleCheckboxChange(v,item){
  if(v){
    this.listUnopen=this.listUnopen.filter((item2)=>item2.id!=item.id)
    this.listOpen.push(item)
  }else{
    this.listOpen=this.listOpen.filter((item2)=>item2.id!=item.id)
    this.listUnopen.push(item)
  }
},
    isNodeDisabled(node){
      return !node.children || node.children.length === 0;
    },
    updateItemValue(item) {
      if(!item.value||item.value<0){
        item.value=0
      }
      // console.log(id);
    },
    purifyList(list) {
      return list.map((item) => {
        return {
          ...item,
          value: '',
          ischeck:false,
          name: `${item.countryCn}(${item.countryEn})`,
        };
      });
    },
    searchFilter() {
      return true;
    },
  },
  mounted() {
    this.aid = this.$route.query.id;
    this.$http.get("/country/list").then((res) => {
      this.list = this.purifyList(res.data);

      if (this.$route.query.PluginType == -3 || this.$route.query.PluginType == -2) {
        this.$http
          .get("/api/app/release/downloadSettingWithCountry", {
            avid: this.aid,
          })
          .then((res2) => {
            let map = new Map();
            res.data.forEach((item) => {
              map.set(item.iso2, item);
            });
            res2.data.forEach((item2) => {
              let tempItem=map.get(item2.iso2);
              if(!tempItem){
                return
              }
              this.listOpen.push({
                ...tempItem,
                value: item2.alloweddownloads,
                ischeck:true,
                name: `${tempItem.countryCn}(${tempItem.countryEn})`,
              });
              map.delete(item2.iso2);
            });
            this.listUnopen =this.purifyList([...map.values()]);
            this.currentList=[...this.listOpen,...this.listUnopen]
            this.updataGlist()
            this.groupChange()
            // let set2 = new Set(res2.data.map(item => item.id));
            // let commonIds=[...new Set([...set1].filter(x => set2.has(x)))];
            // this.list = this.purifyList(list);
            // this.items.push({
            //   id: -1,
            //   name: `所有国家/地区${res2.data.length}个`,
            //   children: [...this.list],
            // });
          });
      } else {
        this.currentList=[...this.list]
        this.listUnopen=[...this.list]
        this.updataGlist()
        this.groupChange()
      }
      
    });
  },
};
</script>
<style scoped>
.nation-head{
  margin-top: 0;
}
.input-box{
  display: flex;
  align-items: center;
}
.checkout-text{
  margin-top: 0;
}
.nation-box{
  display: flex;
  justify-content: space-between;
  height: 60px;
}
.row-box {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.selectb{
  margin-right:13px ;
}
.colput{
  display: flex;
  align-items: center;
}
.col{
  padding: 0;
}
.row{
  margin:0;
}
::v-deep .v-treeview-node__content {
  display: flex;
  align-items: center;
  justify-content: space-around;
}
::v-deep .v-label {
  padding: 0;
}
::v-deep .v-messages{
  display: none;
}
::v-deep .v-input__slot {
  margin: 0;
}
.line{
  width: 100%;
  height: 1px;
  margin: 10px 0 15px;
  background-color: rgba(204, 200, 200, 1);
}
::v-deep .v-treeview-node__label {
  flex: 1.5;
}
::v-deep .v-treeview-node__append {
  flex: 1;
}
</style>
