<template>
  <v-container fluid>
    <v-row no-gutters class="d-flex justify-space-between mt-10 mb-6">
      <h1 class="page-title">发布策略</h1>
    </v-row>
    <div class="content">
      <v-row no-gutters >
        <div>
          <h3 class="mb-3">发布策略</h3>
          <!-- 单选框 -->
          <v-radio-group v-model="publishV">
            <v-radio
              v-for="item in publishs"
              :key="item.value"
              :label="item.lable"
              :value="item.value"
            ></v-radio>
          </v-radio-group>
        </div>
      </v-row>
      <v-row no-gutters class="mt-2 mb-6">
        <div v-show="publishV==-3||publishV==-2" style="min-width: 777px;">
          <CountrySelect ref="selectRef"  :publishV="publishV"></CountrySelect>
        </div>
        <div v-show="publishV==0">
          <v-text-field
                v-model="limitV"
                label="发布数量"
                :rules="nonNegativeIntegerRule"
                hide-details="auto"
                outlined
                @input="updateItemValue"
              ></v-text-field>
        </div>
      </v-row>
    </div>
    <!-- <div class="bottom">
    <v-btn
          class="mr-6"
        >
          取消
        </v-btn>
    <v-btn
          color="primary"
          @click="save"
          dark
        >
          保存
        </v-btn>
      </div> -->
      <v-app-bar 
      fixed
      class="pr-5"
      height="68" 
      color="white"
      bottom>
      <v-spacer></v-spacer>
      <v-btn class="mr-2"  text @click="()=>$router.go(-1)">取消</v-btn>
      <v-btn  elevation="0" @click="save"> 发布</v-btn>
    </v-app-bar>
  </v-container>
</template>

<script>
import CountrySelect from "@/components/CountrySelect/CountrySelect.vue";
export default {
  data() {
    return {
      publishV: -1,
      aid: "",
      nonNegativeIntegerRule: [
        v => v === '' || /^\d+$/.test(v) || '请输入非负整数', // 输入为空或为非负整数
      ],
      limitV: 0,
      publishs:[
        {lable:'全量发布(所有国家/地区)',value:-1},
        {lable:'限量发布(所有国家/地区)',value:0},
        {lable:'全量发布(开放国家/地区)',value:-2},
        {lable:'限量发布(开放国家/地区)',value:-3},
      ]
    };
  },

  mounted() {
    this.aid=this.$route.query.id
    this.publishV=+this.$route.query.PluginType
    this.limitV=this.$route.query.limit>=0?this.$route.query.limit:0
  },
  components: {
    CountrySelect,
  },
  methods: {
    updateItemValue(){
      if(!this.limitV||this.limitV<0){
        this.limitV=0
      }
    },
    allowedDownloads(){
      return this.$http.get('/api/app/release/allowedDownloads',{versionId:this.aid,allowedDownloads:this.limitV})
    },
    allowedDownloadsWithCountry(params){
      return this.$http.post('/api/app/release/allowedDownloadsWithCountry',{data:params},{'Content-Type':'application/json'})
    },
    setReleasePolicy(){
      return this.$http.get('/api/app/release/setReleasePolicy',{versionId:this.aid,allowedDownloads:this.publishV})
    },
    async save() {
      if(this.publishV==-3){
        const isValid = this.$refs.selectRef.listOpen.every(rule => rule.value >= 0);
        if(!isValid){
        return this.$toast.error('请输入非负整数')
        }
      }else if(this.publishV==0){
        const isValid = this.nonNegativeIntegerRule.every(rule => rule(this.limitV) === true);
        if(!isValid){
          return this.$toast.error('请输入非负整数')

      }
      }
      
      let tempTreeSelected=this.$refs.selectRef.listOpen
      let tempList=this.$refs.selectRef.list
      let mapItems=new Map(tempTreeSelected.map(item => [item.iso2, item]));
      // let listUnopen=tempList.filter(item => !mapItems.has(item.iso2));
      let tempDownloadLimit=''
      let params=tempList.map(item=>{
        if(this.publishV==-2){
          tempDownloadLimit=mapItems.has(item.iso2)?-1:-2
        }else{
          tempDownloadLimit=mapItems.has(item.iso2)?mapItems.get(item.iso2).value:-2
        }
        return {
          avid:this.aid,
          downloadLimit:tempDownloadLimit,
          iso2:item.iso2
        }
      })
        //设置下载限制
        //设置发布策略
      if(this.publishV==-3 || this.publishV==-2){
        await this.setReleasePolicy()
        await this.allowedDownloadsWithCountry(params)
        //返回上一页
        this.$router.push({ name: "Tracks" });
      }
      else{
        await Promise.all([this.allowedDownloads(),this.setReleasePolicy()])
          //返回上一页
          this.$router.push({ name: "Tracks" });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.title1 {
  margin-left: 40px;
}
.bottom{
  width: 100%;
  display: flex;
  justify-content: end;
  margin-top: 20px;
}
.content {
  box-shadow: 0 3px 11px 0 #E8EAFC, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A ;
  padding:28px;
  box-sizing: border-box;
}
</style>
